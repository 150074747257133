import { Component, OnInit, ViewChild } from '@angular/core';
import { HistoricaldataService } from '../../services/historicaldata.service';
import { ChartComponent, ApexAxisChartSeries, ApexChart, ApexXAxis, ApexTitleSubtitle, ApexPlotOptions } from 'ng-apexcharts';
import { NgApexchartsModule } from 'ng-apexcharts';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCardModule } from '@angular/material/card';

interface ChartData {
    Date: string;
    Open: number;
    High: number;
    Low: number;
    Close: number;
}

interface DataServiceResponse {
    BSE_DAILY_ONE_YEAR?: ChartData[];
    NSE_DAILY_ONE_YEAR?: ChartData[];
    BSE_errorMessage?: string;
    NSE_errorMessage?: string;
}

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  title: ApexTitleSubtitle;
  plotOptions: ApexPlotOptions;
};

@Component({
    selector: 'app-historicaldatachart',
    templateUrl: './historicaldatachart.component.html',
    styleUrls: ['./historicaldatachart.component.scss'],
    imports: [CommonModule, NgApexchartsModule, MatProgressSpinnerModule, MatCardModule],
    standalone: true
})
export class HistoricaldatachartComponent implements OnInit {
    @ViewChild('bseChart') bseChart!: ChartComponent;
    @ViewChild('nseChart') nseChart!: ChartComponent;

    public bseChartOptions: ChartOptions = {
        series: [{
            data: []
        }],
        chart: {
            type: 'candlestick',
            height: 500
        },
        title: {
            text: '',
            align: 'left'
        },
        xaxis: {
            type: 'datetime',
            categories: []
        },
        plotOptions: {
            candlestick: {
                colors: {
                    upward: '#673AB7',
                    downward: '#FFC107' 
                },
                wick: {
                    useFillColor: true
                }
            }
        }
    };

    public nseChartOptions: ChartOptions = {
        series: [{
            data: []
        }],
        chart: {
            type: 'candlestick',
            height: 500
        },
        title: {
            text: '',
            align: 'left'
        },
        xaxis: {
            type: 'datetime',
            categories: []
        },
        plotOptions: {
            candlestick: {
                colors: {
                    upward: '#673AB7',
                    downward: '#FFC107' 
                },
                wick: {
                    useFillColor: true
                }
            }
        }
    };

    data: DataServiceResponse = {};
    isLoading = true;

    constructor(private historicaldataService: HistoricaldataService) {}

    ngOnInit() {
        this.historicaldataService.fetchData().subscribe({
            next: data => {
                this.data = data;
                this.isLoading = false;

                if (this.data.BSE_DAILY_ONE_YEAR) {
                    this.bseChartOptions = this.createChartOptions(this.data.BSE_DAILY_ONE_YEAR, 'BSE Chart - S&P BSE SENSEX');
                } else {
                    console.error('BSE Error:', this.data.BSE_errorMessage);
                }

                if (this.data.NSE_DAILY_ONE_YEAR) {
                    this.nseChartOptions = this.createChartOptions(this.data.NSE_DAILY_ONE_YEAR, 'NSE Chart - NIFTY 50');
                } else {
                    console.error('NSE Error:', this.data.NSE_errorMessage);
                }
            },
            error: error => {
                console.error('There was an error!', error);
                this.isLoading = false;
            }
        });
    }

    createChartOptions(data: ChartData[], title: string): ChartOptions {
        const seriesData = data.map((item: ChartData) => {
            // Convert item.Date to 'YYYY-MM-DD' format
            const date = item.Date.split('-').reverse().join('-');
            return {
                x: date,
                y: [item.Open, item.High, item.Low, item.Close]
            };
        });

        return {
            series: [{
                data: seriesData
            }],
            chart: {
                type: 'candlestick',
                height: 500,
                zoom: {
                    enabled: true
                },
                toolbar: {
                    autoSelected: 'pan',
                    tools: {
                        download: false, // Disable download button
                        pan: true,
                        zoomin: true,
                        zoomout: true,
                        reset: true
                    }
                }
            },
            plotOptions: {
                candlestick: {
                    colors: {
                        upward: '#673AB7',
                        downward: '#FFC107' 
                    },
                    wick: {
                        useFillColor: true
                      }
                }
            },
            title: {
                text: title,
                align: 'left'
            },
            xaxis: {
                type: 'datetime',
                categories: [],
                labels: {
                    show: true
                },
                axisBorder: {
                    show: true
                },
                axisTicks: {
                    show: true
                }
            }
        };
    }
}