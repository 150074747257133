import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HistoricaldataService {
  
  private url = `${environment.apiUrl}/v1/historical`;

  constructor(private http: HttpClient) {}

  fetchData(): Observable<any> {
    return this.http.get(this.url).pipe(
      map(response => response),
      catchError(error => {
        console.error('error', error);
        return throwError(() => error);
      })
    );
  }
}