<mat-card class="raised-card">
    <mat-card-header>
        <mat-card-title>
            Get started for FREE
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <p>
            Enter your active number where SMS login code can be sent.
            <mat-radio-group aria-label="Select a messaging app">
                <mat-radio-button value="whatsapp" checked>
                    <img src="assets/images/whatsapp.png" alt="WhatsApp" style="width: 20px; height: 20px;">
                    WhatsApp
                </mat-radio-button>
                <mat-radio-button value="telegram">
                    <img src="assets/images/telegram.png" alt="Telegram" style="width: 20px; height: 20px;">
                    Telegram
                </mat-radio-button>
            </mat-radio-group>
        </p>
        <form>
            <mat-form-field>
                <input type="text" placeholder="Country" aria-label="Country" matInput [formControl]="countryControl" [matAutocomplete]="auto">
                <mat-autocomplete #auto="matAutocomplete">
                    <mat-option *ngFor="let country of filteredCountries | async" [value]="country">
                        {{country}}
                    </mat-option>
                </mat-autocomplete>
                <input matInput type="tel" autocomplete="tel" placeholder="Enter your WhatsApp number" value="99999 11111">
            </mat-form-field>
            <div>
                <p>By clicking "Login" you consent to receiving an SMS login token and WhatsApp/Telegram updates from StockToolAI.</p>
                <button mat-raised-button color="primary" class="login-button">Login</button>
            </div>
        </form>
    </mat-card-content>
</mat-card>