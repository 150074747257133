<div *ngIf="isLoading">
    <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
</div>
<div *ngIf="!isLoading">
    <mat-card *ngIf="bseChartOptions" class="raised-card">
        <mat-card-content>
            <apx-chart #bseChart [series]="bseChartOptions.series" [chart]="bseChartOptions.chart" [title]="bseChartOptions.title" [xaxis]="bseChartOptions.xaxis"></apx-chart>
        </mat-card-content>
    </mat-card>
    <div class="spacer"></div>
    <mat-card *ngIf="nseChartOptions" class="raised-card">
        <mat-card-content>
            <apx-chart #nseChart [series]="nseChartOptions.series" [chart]="nseChartOptions.chart" [title]="nseChartOptions.title" [xaxis]="nseChartOptions.xaxis"></apx-chart>
        </mat-card-content>
    </mat-card>
</div>