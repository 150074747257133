import { Component } from '@angular/core';
import { MatToolbarModule } from '@angular/material/toolbar';

@Component({
  selector: 'app-footer-bar',
  standalone: true,
  templateUrl: './footer-bar.component.html',
  styleUrl: './footer-bar.component.scss',
  imports: [MatToolbarModule],
})
export class FooterBarComponent {

}
