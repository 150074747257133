import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common'; 


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  imports: [CommonModule, ReactiveFormsModule, MatCardModule, MatFormFieldModule, MatInputModule, MatButtonModule, MatSelectModule, MatRadioModule, MatAutocompleteModule],
  standalone: true
})
export class LoginComponent implements OnInit {
  countryControl = new FormControl();
  countries: string[] = ['International', 'Zimbabwe', 'United States', 'United Kingdom', 'Canada', 'Australia', 'India', 'China', 'Germany', 'France']; // Add all countries here
  filteredCountries: Observable<string[]> = of(this.countries);
  ngOnInit() {
    this.filteredCountries = this.countryControl.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filterCountries(value))
      );
  }

  private _filterCountries(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.countries.filter(country => country.toLowerCase().includes(filterValue));
  }
}